export const environment = {
  production: false,
  staging: true,
  development: false,
};

export const ENV = {
  JMG_ENV: 'production',
  STRIPE_PUBLISHABLE_KEY: 'pk_test_O2EbaXdVed1G7nlA1182s0Ue',
  SQUARE_APPLICATION_ID: 'sandbox-sq0idb-mnUng0h-E4YuSnZkxv2syQ',
  SQUARE_LOCATION_ID: 'LWZZXBTZ0ZPAA',
  API_BASE_URL: 'https://staging-api.jackpot.game/api',
  SOCKET_BASE_URL: 'https://staging-api.jackpot.game',
  AWS_S3_BASE_URL: 'https://jmg-songs.nyc3.cdn.digitaloceanspaces.com/',
  LOBBY_AWS_S3_BASE_URL: 'https://jackpot-music-game.nyc3.cdn.digitaloceanspaces.com/',
  VERSION: "STAGING 1.1.2a"

};
